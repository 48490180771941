import styled from "styled-components";

import Epost from ".././components/Epost";

import Blandat from "./../images/artister/2024/blandat.jpg";

const ExtraImage = styled.img`
  width: 180px;
  float: left;
  margin: 2px 1rem 0.3rem 0;
`;


const artistInfo = [

  {
    id: 1,
    namn: function () {
      return <>SÄSONGSÖPPNING</>;
    },
    info: function () {
      return (
        <>
          <p>
            Påskafton kl. 11 öppnar krogen dörrarna för säsongen!<br />
            Sedan kommer vi ha öppet lördagar, söndagar och helgdagar kl. 11 - 15.
            <br />Fr.o.m. skolavslutningen 12 juni har vi öppet dagligen; vardagar 11 - 17 och helger 11 - 16.
          </p>
          <p>
            Varmt välkomna till påsk!
          </p>
        </>
      );
    },
    datum: "2025-04-19",
    img: "säsongsöppning",
    pris: 1,
    tid: "11:00",
    kväll: false,
    music: false,
    konst: false,
    author: false,
  },
  /*
    {
      id: 2,
      namn: function () {
        return <>MIDSOMMARAFTON</>;
      },
      info: function () {
        return (
          <>
            <p>
              Traditionsenligt så firar vi midsommar på Oxelö Krog med dans och lekar kring midsommarstången till levande musik!<br />
  
              Midsommarstången kommer att kläs och resas mellan kl 10 - 12.<br />
              Kl. 13 blir det musik och ringlekar med Lotta och Lena!<br />
  
              <p>Caféet är öppet 10 till 16. Denna dag har vi en speciell midsommarmeny som ser ut så här:
              </p>
              <ul>
                <li>Midsommartallrik 195 kr - 3 sorters sill, varmrökt lax, räkröra, gräddfil, gräslök, kokt ägg, potatis, smör och bröd.</li>
                <li> Varmrökt lax med fransk potatissallad och romröra samt smör och bröd</li>
                <li>Räksmörgås</li>
                <li> Pannkakor med grädde och sylt</li>
              </ul>
              <p>Dessutom har vi förstås fika med bland annat vår fina midsommartårta med jordgubbar</p>
              Varmt välkomna!<br /><br />
              Glöm inte att ta med folkdräkt om du har en!
            </p>
            <p>OBS! På midsommarafton kommer det inte vara möjligt att parkera på Kroggränd eftersom vi kommer ha dansen där. Vi hänvisar istället till stora parkeringen.</p>
          </>
        );
      },
      datum: "2024-06-21",
      img: "midsommar",
      pris: 0,
      tid: "",
      kväll: false,
      music: true,
    },
  
    {
      id: 3,
      namn: function () {
        return <></>;
      },
      info: function () {
        return (
          <>
            <p>
            </p>
            <p>
            </p>
          </>
        );
      },
      datum: "2025-06-01",
      img: "",
      pris: 0,
      tid: "14:00",
      kväll: false,
      music: false,
    },
  */

];

export default artistInfo;