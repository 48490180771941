import React, { useState, useEffect } from "react";
import DropWindow from "./../components/DropWindow";

const Content = () => {
  return (
    <>
      <h2>AKTUELLT <nobr>PÅ OXELÖ KROG</nobr></h2>
      <div>
        <p>
          Nu är det sjätte året som vi, Thord och Maria, driver Oxelö Krog.
          Även i år har vi kvällskonserter, författartisdagar, konstutställningar och en Bellmandag.<br />

        </p>
        <p>
          Det är underbart att sitta på krogbacken med utsikt över öar och båtliv. Men vi har även fina rum inne på krogen där man kan sitta även om det regnar.
        </p>
        <p>
          Varmt välkomna till påsk! Då öppnar vi dörrarna för säsongen!
        </p>
      </div>

    </>

  );
}


function Aktuellt() {
  const [dropdown, setDropdown] = useState('');

  function getAktuellt() {
    setDropdown('aktuellt');
  }

  useEffect(() => {
    getAktuellt();
  }, []);

  return (
    <>
      <DropWindow dropdown={dropdown} setDropdown={setDropdown} >
        <Content />
      </DropWindow>
    </>
  );
}

export default Aktuellt;