import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DropWindow from "./../components/DropWindow";

const UL = styled.ul`
  display: inline-block;
   text-align: left;
   margin-top: 0;
   margin-left: auto;
   margin-right: auto;

   li {
    list-style: disc;
    margin-top: 0.8rem;
   }
`;

const OpenTimes = () => {
  return (
    <>
      <h2>ÖPPETTIDER</h2>
      <p>
        Nu har vi stängt för säsongen.<br />
      </p>

      <p>
        Vi slår upp dörrarna för säsongen till påsk.<br />
        Påskafton, påskdagen och annadag påsk har vi öppet 11 - 15.<br />
        Därefter kommer vi ha öppet lördagar, söndagar och helger 11 - 15.
      </p>

      <p>
        Fr.o.m. skolavslutningen 12 juni har vi öppet dagligen; vardagar 11-17 och helger 11-16.
      </p>

      {
        /*
      <UL>
        <li>Vardagar 11 - 17</li>
        <li>Lördagar och söndagar 11 - 16</li>

      </UL>

      <p>
        Därefter, i september och oktober, kommer vi ha öppet lördagar och söndagar 11 - 15.
      </p>

        */
      }

      <p>Varmt välkomna till påsk!</p>
    </>
  );
};

function Open() {
  const [dropdown, setDropdown] = useState("");

  function getOpen() {
    setDropdown("open");
  }

  useEffect(() => {
    getOpen();
  }, []);

  return (
    <DropWindow dropdown={dropdown} setDropdown={setDropdown}>
      <OpenTimes />
    </DropWindow>
  );
}

export default Open;